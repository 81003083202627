"use client";

import { useEffect } from "react";
import errorImg from "@shared/assets/images/error.png";
import Image from "next/image";
import { Button } from "@shared/ui";

export default function Error({ error }: { error: Error & { digest?: string }; reset: () => void }) {
  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <div className="w-screen flex flex-col justify-center items-center gap-5">
      <Image src={errorImg} width={450} alt="sad woman looking at notebook" />
      <h2 className="dark:text-dark-text">Oops! Something went wrong.</h2>
      <p className="text-center dark:text-dark-text">
        We encountered an error while processing your request. Please click on the button below and try again.
      </p>
      <Button onClick={() => window.location.reload()}>Refresh</Button>
    </div>
  );
}
